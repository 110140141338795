import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ENotificationType, NotificationComponent} from './notification.component';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public constructor(private snackBar: MatSnackBar) {
    }

    private show(message: string, type: ENotificationType): void {
        this.snackBar.openFromComponent(NotificationComponent, {
            data: {
                message,
                type,
            },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: type,
        });
    }

    public success(message: string): void {
        this.show(message, ENotificationType.Success);
    }

    public error(message: string): void {
        this.show(message, ENotificationType.Error);
    }
}
