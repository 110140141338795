<div [ngClass]="data.type">
    <span matSnackBarLabel>
        <span class="material-icons">
            {{
                data.type === _ENotificationType.Success
                    ? "check_circle"
                    : "error"
            }}
        </span>
        {{ data.message }}
    </span>
    <span matSnackBarActions>
        <button
            mat-button
            matSnackBarAction
            (click)="snackBarRef.dismissWithAction()"
        >
            <span
                class="material-icons close"
                (click)="snackBarRef.dismiss()"
                (keyup.enter)="snackBarRef.dismiss()"
                tabindex="0"
                >close</span
            >
        </button>
    </span>
</div>
