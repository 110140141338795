import {Component, HostBinding, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef} from '@angular/material/snack-bar';
import {NgClass} from '@angular/common';
import {MatButton} from '@angular/material/button';

export enum ENotificationType {
    Success = 'success',
    Error = 'error',
}

interface INotificationData {
    type: ENotificationType,
    message: string,
}

@Component({
    selector: 'app-notification',
    standalone: true,
    imports: [
        NgClass,
        MatSnackBarLabel,
        MatSnackBarActions,
        MatButton,
        MatSnackBarAction,
    ],
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss',
})
export class NotificationComponent {
    @HostBinding('class') public componentClass;

    public _ENotificationType = ENotificationType;

    public constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: INotificationData,
        public snackBarRef: MatSnackBarRef<NotificationComponent>,
    ) {
        this.componentClass = this.data.type;
    }
}
