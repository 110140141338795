import {Injectable} from '@angular/core';
import {Observable, tap, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {IOrganization, IUser, TExistingEntity} from '../../shared/models';
import {NotificationService} from '../../shared/components/notification/notification.service';

export const AUTH_KEY = '_auth';

export interface IAuthCompany extends TExistingEntity<IOrganization> {
    ecmrLimit: number;
}

export interface IAuthentication {
    readonly token: string;
    readonly refreshToken: string;
    readonly profile: TExistingEntity<IUser>;
    readonly company: IAuthCompany;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private _authentication?: IAuthentication;

    public get authentication(): IAuthentication | undefined {
        return this._authentication;
    }

    public get authenticated(): boolean {
        return !!this._authentication;
    }

    public constructor(
        private readonly http: HttpClient,
        private readonly notificationService: NotificationService,
    ) {
        const auth = localStorage.getItem(AUTH_KEY);
        if (auth) {
            try {
                this._authentication = JSON.parse(auth);
            } catch (e) {
                console.error(e);
            }
        }
    }

    private setAuthentication(value: IAuthentication | undefined): void {
        this._authentication = value;
        if (value) {
            localStorage.setItem(AUTH_KEY, JSON.stringify(value));
        } else {
            localStorage.removeItem(AUTH_KEY);
        }
    }

    public signIn(username: string, password: string): Observable<IAuthentication> {
        return this.http.post<IAuthentication>('/token', {username, password})
            .pipe(
                tap({
                    next: (authentication) => {
                        this.setAuthentication(authentication);
                    },
                    error: error => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
                        this.notificationService.error(error.error?.message);
                    },
                }),
            );
    }

    public refresh(): Observable<IAuthentication> {
        if (!this._authentication) {
            return throwError(() => new Error('Not authenticated'));
        }
        return this.http.post<IAuthentication>('/token/refresh', this._authentication.refreshToken)
            .pipe(
                tap({
                    next: (authentication) => {
                        this.setAuthentication(authentication);
                    },
                    error: (err) => {
                        if (err instanceof HttpErrorResponse && err.status == 401) {
                            this.logout();
                            document.location = '/sign-in';
                        }
                    },
                }),
            );
    }

    public logout(): void {
        this.setAuthentication(undefined);
    }
}
